import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { useCookies } from 'react-cookie';
import SEO from "../components/seo/seo"
import Layout from "../components/layout/layout"
import AppBar from "../components/appBar/appBar"
import MainSection from "../components/mainSection/mainSection"
import SecondarySection from "../components/secondarySection/secondarySection"
import PageSection from "../components/pageSection/pageSection"
import MarketplaceForm from "../components/marketplaceForm/marketplaceForm"
import Card from "../components/card/card";
import OptOut from "../components/optOut/optOut"
import IFrame from "../components/iFrame/iFrame"
import Modal from "../components/modal/modal"

import * as ACTIONS from "../state/actions"
import FAQs from "../components/faqs/faqs";

const tcPdf = <IFrame file="../TC.pdf" title="Términos de uso"/>;
const pcPdf = <IFrame file="../PC.pdf" title="Política de privacidad"/>;
const footerDesc = "";
const contentMaxWidth = "780px";

const dataFilters = [
    {
        id: "general",
        label: "General"
    },
    {
        id: "onboarding",
        label: "Requisitos de apertura"
    }
];

const dataFAQs = [
    {
        title: "¿Por qué se llama EVVA?",
        description: "<p>EVVA proviene de nuestro manifiesto:<br /><strong>Emprendimiento:</strong> nuestra inspiración diaria. Nos enfocamos en las necesidades financieras de los emprendedores para que ellos se enfoquen en lo que realmente necesitan: su empresa.<p><p/><strong>Veracidad:</strong> ser transparente y franco con nuestros clientes. Desaparecemos las comisiones ocultas y tarifas complicadas.<p><p/><strong>Velocidad:</strong> Todo está cambiando constantemente. El ser ágil y adaptable es la clave para tener éxito. Creamos experiencias financieras ágiles con el objetivo final de hacer que las pequeñas empresas crezcan al siguiente nivel.<p><p/><strong>Accesibilidad:</strong> siendo inclusivos. Nuestros servicios están abiertos a todos los empresarios que quieran crear un impacto y contribuir a la economía del país.</p>",
        categories: ["general"]
    },
    {
        title: "¿Para quién es EVVA?",
        description: "<p>Actualmente estamos abiertos a cualquier residente mexicano con identificación INE y que sea director de una compañía estructurada como una sociedad  SAS, SAPI, SA de CV o SC o simplemente freelancer es decir personas físicas con actividad empresarial.</p>",
        categories: ["general"]
    },
    {
        title: "¿EVVA es un banco?",
        description: "<p>No, EVVA es una plataforma digital que te conecta a diversos servicios financieros relevantes para tu empresa o negocio. Todos los servicios financieros ofrecidos en la plataforma son proveídos por una o más instituciones financieras, las cuales están reguladas y supervisadas por la CNBV, BANXICO y CONDUSEF.</p>",
        categories: ["general"]
    },
    {
        title: "¿Qué es exactamente la cuenta empresarial ofrecida a través de EVVA?",
        description: "<p>EVVA es una cuenta que te permite invertir tu dinero de tesorería cuando no lo estás utilizando, con la finalidad de ganar rendimientos diarios. Cuentas con liquidez inmediata por lo tanto puedes retirar tu dinero en cualquier momento, la cuenta es emitida por Kuspit Casa de Bolsa y el fondo es administrados por Scotia Global Asset Management, una compañía de ScotiaBank. Para obtener información detallada sobre este fondo, puedes leer la descripción detallada de este fondo en este <a href='https://scotiabankfiles.azureedge.net/scotia-bank-mexico/spanish/pdf/personas/fondos-de-inversion/prospectos/Prospecto_SCOTIAG.pdf?t=1592919143996' target='_blank' rel='noopener noreferrer'>documento</a></p>",
        categories: ["general"]
    },
    {
        title: "¿Existe algún riesgo en la cuenta empresarial?",
        description: "<p>El horizonte de Inversión es corto plazo. El Fondo está expuesto principalmente a tasas de interés, con una duración de como máximo 1 año en el portafolio, además de mantener al menos un 30% del activo neto del Fondo en valores de fácil realización y/o valores con plazo de vencimiento menor a 3 meses, por lo que se considera que el riesgo de este Fondo es bajo. El principal riesgo asociado a este Fondo son las variaciones en las tasas de interés y el riesgo cambiario. Por lo cual el patrimonio se puede ver afectado en el corto plazo derivado de los movimientos en alzas de las tasa de interés y al tipo de cambio.</p>",
        categories: ["general"]
    },
    {
        title: "¿Qué otros servicios ofrece EVVA?",
        description: "<p>Con nuestros productos y aliados, estamos desarrollando un conjunto de servicios para tu negocio, desde transacciones seguras en caso de vender o comprar hasta una visualización fácil y clara de tu situación fiscal. Visita nuestra página de <a href='/marketplace' target='_self' rel='noopener noreferrer'>marketplace financiero para más información</a>.</p>",
        categories: ["general"]
    },
    {
        title: "¿Cuál es la mejor forma de contactar a EVVA?",
        description: "<p>Si eres cliente nos puedes contactar a través del chat de la aplicación web en los horario de 7:00 AM a 17:00 PM. De igual forma nos puedes mandar un correo a <a href='mailto:ayuda@evvafinanzas.com' target='_blank' rel='noopener noreferrer'>ayuda@evvafinanzas.com</a> en caso de tener alguna duda, idea o problema acerca del servicio.</p><p>Si sólo tienes curiosidad no dudes en contactarnos en <a href='mailto:info@evvafinanzas.com' target='_blank' rel='noopener noreferrer'>info@evvafinanzas.com</a> Nos encantará escuchar de ti y de responder preguntas.</p>",
        categories: ["general"]
    },
    {
        title: "¿Cuánto cuesta EVVA?",
        description: "<p>Tenemos planes flexibles que crecen contigo, para empresas recién establecidas tenemos un plan totalmente gratuito, para empresas con mayor tracción y facturación tenemos un plan mensual de $175 MXN.</p>",
        categories: ["general"]
    },
    {
        title: "¿Qué necesito para abrir una cuenta empresarial digital?",
        description: "<p>Para poder adquirir tu cuenta empresarial digital recuerda que debes ser el apoderado legal de tu empresa. De igual forma se requieren los siguientes documentos para ser registrados en nuestra plataforma:</p><p>Empresas constituidas como persona moral:</p><ul><li>Clave CURP de apoderado legal</li><li>Foto de frente de la tarjeta de elector del apoderado legal, debe ser en formato JPG, PNG, la puedes tomar con tu celular o laptop, sólo asegura que sea totalmente clara</li><li>Foto de reverso de la tarjeta de elector  del apoderado legal, debe ser en formato JPG, PNG, la puedes tomar con tu celular o laptop, sólo asegura que sea totalmente clara</li><li>Acta constitutiva en formato pdf</li><li>Acta de cambio de poderes, esto aplica en caso que tu empresa haya sufrido algún cambio de apoderado legal</li><li>Comprobante de domicilio fiscal en pdf, reciente no mayor a 3 meses, puede ser un recibo de agua, luz o teléfono</li><li>Constancia fiscal de tu negocio, la cual la puedes generar en el portal SAT</li><li>FIEL (firma digital de tu negocio) ten a la mano tus archivos .KEY .CER y contraseña</li></ul><p>Si eres persona física con actividad empresarial:</p><ul><li>Clave CURP</li><li>Foto de frente de la tarjeta de elector del apoderado legal, debe ser en formato JPG, PNG, la puedes tomar con tu celular o laptop, sólo asegura que sea totalmente clara</li><li>Foto de reverso de la tarjeta de elector  del apoderado legal, debe ser en formato JPG, PNG, la puedes tomar con tu celular o laptop, sólo asegura que sea totalmente clara</li><li>Comprobante de domicilio fiscal en pdf, reciente no mayor a 3 meses, puede ser un recibo de agua, luz o teléfono</li></ul>",
        categories: ["onboarding"]
    },
    {
        title: "¿Cuáles son los datos requeridos de mi empresa?",
        description: "<p>Domicilio fiscal, teléfono y RFC de tu empresa</p>",
        categories: ["onboarding"]
    }
];

const Marketplace = () => {
    const appState = useSelector(state => state.appReducer);
    const dispatch = useDispatch();

    const [cookies, setCookie] = useCookies(['consent']);

    const marketplaceFormHandler = () => {
        window.scroll({ top: 0, left: 0, behavior: "smooth" });

        const modal = {
            display: true,
            content: <MarketplaceForm lang={appState.lang} title="¡Gracias por tu interés!" subtitle="Estamos apunto de lanzar 🚀! Regístrate y solicita tu acceso preferencial cuanto antes." extraFields="services" amplitude={appState.amplitude} amplitudeClickEvent="PreSignUpMKTCreated"/>
        };

        dispatch(ACTIONS.showModal(modal))
    };

    const contactUsFormHandler = () => {
        window.location.href="mailto:partners@evvafinanzas.com";
    };

    const termsUseHandler = () => {
        window.scroll({ top: 0, left: 0, behavior: "smooth" });
        dispatch(ACTIONS.showModal({display: true, content: tcPdf }))
    };

    const privacyPolicyHandler = () => {
        window.scroll({ top: 0, left: 0, behavior: "smooth" });
        dispatch(ACTIONS.showModal({display: true, content: pcPdf }))
    };

    const cookieHandler = () => {
        setCookie("consent", "dismiss", { path: '/' });
    };

    const closeModal = () => dispatch(ACTIONS.hideModal());

    const categoryFAQsHandler = (id) => {
        const { categoryFAQs } = appState;
        const categoryId = categoryFAQs.find(item => item === id);

        if (categoryId) {
            const index = categoryFAQs.indexOf(categoryId);

            if (index > -1 && categoryFAQs.length > 1) {
                categoryFAQs.splice(index, 1);
            }

        } else {
            // categoryFAQs.push(id)
            categoryFAQs[0] = id
        }

        dispatch(ACTIONS.setCategoryFAQs(categoryFAQs));
    };

    return (
        <Layout lang={appState.lang} footerDesc={footerDesc} privacyPolicyAction={privacyPolicyHandler} termsUseHandlerAction={termsUseHandler}>
            <SEO lang={appState.lang} page="marketplace" title="Entiende, controla y haz crecer su negocio" description="EVVA simplifica la vida financiera de PyMEs, Startups y Freelancers."/>
            {appState.modal.display && <Modal content={appState.modal.content} closeModal={closeModal}/> }
            {!cookies.consent &&
            <OptOut text="Utilizamos cookies para garantizar que tengas la mejor experiencia en nuestro sitio web." privacyPolicyAction={privacyPolicyHandler} privacyPolicyLabel="Ver Política de privacidad" cookieAction={cookieHandler}/>
            }
            <MainSection title="Entiende, controla y haz crecer tu negocio." description="<h3>EVVA simplifica la vida financiera de PyMEs, Startups y Freelancers.</h3>" position="left" image="hero-marketplace.png" actionLabel="Solicita tu invitación" action={marketplaceFormHandler} amplitude={appState.amplitude} amplitudeClickEvent="ClickPreSignUpMKT">
                <AppBar pathname={appState.path} />
            </MainSection>
            <PageSection maxColumns="4" title="Sabemos que no es fácil ser emprendedor...">
                <Card
                    imageHeight="160px"
                    imageWidth="240px"
                    image="beaurocracy-icon.png"
                    description="<p>Burocracia, papeleo, largas llamadas y, visitas  al banco, semanas de esfuerzo para obtener una cuenta bancaria comercial</p>"
                    textAlign="center"
                    descriptionColor="#363636"
                />
                <Card
                    imageHeight="160px"
                    imageWidth="250px"
                    image="insane-interest-rates-icon.png"
                    description="<p>Obtener financiamiento es complicado... letras pequeñas, alta tasas de interés y falta de transparencia</p>"
                    textAlign="center"
                    descriptionColor="#363636"
                />
                <Card
                    imageHeight="160px"
                    imageWidth="245px"
                    image="sat-tax-information-icon.png"
                    description="<p>¿Hablamos de tu información fiscal? No gracias. Es difícil de entender, manejar, procesar y administrar</p>"
                    textAlign="center"
                    descriptionColor="#363636"
                />
                <Card
                    imageHeight="160px"
                    imageWidth="220px"
                    image="insecure-transaction-icon.png"
                    description="<p>Inseguridad y fraude por todos lados al intentar comprar o vender. Los defraudadores están a la vuelta de la esquina</p>"
                    textAlign="center"
                    descriptionColor="#363636"
                />
            </PageSection>
            <PageSection title="Te presentamos EVVA Marketplace" description="Emprendedor no estás sólo...A través de nuestra plataforma digital y socios, EVVA te conecta con los mejores productos financieros, simplificando las necesidades financieras diarias de tu negocio." maxWidth={contentMaxWidth}>
                <Card
                    title="Cuenta Empresarial Digital EVVA Kuspit"
                    description="<p>Dile adios al papeleo, con EVVA Solicita tu cuenta empresarial digital que hace crece tu dinero</p>"
                    descriptionColor="#363636"
                    image="logo-evva-cuenta-empresarial.svg"
                    imageWidth="200px"
                    imageHeight="200px"
                    imageBgColor="_evva_business"
                    link="/cuenta-empresarial-digital"
                    linkLabel="Conoce más"
                    amplitude={appState.amplitude}
                    amplitudeLinkEvent="ClickLearnEVVAAccount"
                    hasBgImage
                    imageShadow
                />
                <Card
                    title="Tu información SAT"
                    description="<p>Mantente adelante del SAT con nuestros dashboard fiscal, recordatorios y estimaciones de impuestos</p>"
                    descriptionColor="#363636"
                    image="logo-sat-white.svg"
                    imageWidth="180px"
                    imageHeight="200px"
                    imageBgColor="_sat_info_es"
                    link="/impuestos-sat"
                    linkLabel="Conoce más"
                    amplitude={appState.amplitude}
                    amplitudeLinkEvent="ClickLearnEVVASAT"
                    hasBgImage
                    imageShadow
                />
                {/*<Card
                    title="EVVA Comunidad"
                    description="<p>Emprendedor, Freelancer, Creador, conecta con tu comunidad y genera ingresos a través de contribuciones directas por parte de tu fans, audiencia, clientes con el objetivo de hacer realidad lo que más amas hacer.</p>"
                    descriptionColor="#363636"
                    image="logo-evva-community.svg"
                    imageWidth="200px"
                    imageHeight="200px"
                    imageBgColor="_evva_donation"
                    link="http://evva.co/"
                    linkLabel="Learn more"
                    amplitude={appState.amplitude}
                    amplitudeLinkEvent="ClickEvvaComunidad"
                    hasBgImage
                    imageShadow
                />*/}
            </PageSection>
            <PageSection maxColumns={2} maxWidth={contentMaxWidth}>
                <Card
                    subtitle="Financiamiento PyME"
                    description="<p>Expande, fondea tu siguiente reto con nuestro financiamiento Uniclick</p>"
                    descriptionColor="#363636"
                    image="logo-uniclick-white.png"
                    imageWidth="240px"
                    imageHeight="200px"
                    imageBgColor="_uniclick"
                    link="/financiamiento"
                    linkLabel="Conoce más"
                    amplitude={appState.amplitude}
                    amplitudeLinkEvent="ClickLearnEVVAUniclick"
                    imageShadow
                />
                <Card
                    subtitle="Transacciones Seguras"
                    description="<p>¿Estás vendiendo o comprando? Evita fraude y asegura tu compra con Fido Escrow Digital</p>"
                    descriptionColor="#363636"
                    image="logo-fido.png"
                    imageWidth="180px"
                    imageHeight="200px"
                    imageBgColor="_fido"
                    link="https://www.escrow.fido.mx/"
                    linkLabel="Conoce más"
                    amplitude={appState.amplitude}
                    amplitudeLinkEvent="ClickLearnEVVAFido"
                    imageShadow
                />
                <Card
                    subtitle="Contrata freelancers"
                    description="<p>Contrata professionales de todo el mundo por proyecto y de forma remota con un solo click. Recibe $300 en tu primera contratación</p>"
                    descriptionColor="#363636"
                    image="logo-worq-lab.png"
                    imageWidth="250px"
                    imageHeight="200px"
                    imageBgColor="_worq_lab"
                    link="https://worqlab.com/"
                    linkLabel="Conoce más"
                    amplitude={appState.amplitude}
                    amplitudeLinkEvent="ClickLearnEVVAWorqLab"
                    imageShadow
                />
                <Card
                    subtitle="Mifiel"
                    description="<p>Cierra tus negocios remotamente utilizando tu firma digital e.Firma </p>"
                    descriptionColor="#363636"
                    image="logo-mifiel.png"
                    imageWidth="200px"
                    imageHeight="200px"
                    imageBgColor="_mifiel"
                    link="https://www.mifiel.com/es/"
                    linkLabel="Conoce más"
                    amplitude={appState.amplitude}
                    amplitudeLinkEvent="ClickLearnEVVAMifiel"
                    imageShadow
                />
                <Card
                    subtitle="EVVA Wallet"
                    description="<p>Aumenta tus ventas y retiene a través de nuestro programas de fidelidad digital</p>"
                    descriptionColor="#363636"
                    image="logo-evva-wallet.svg"
                    imageWidth="180px"
                    imageHeight="200px"
                    imageBgColor="_evva"
                    link="/wallet"
                    linkLabel="Conoce más"
                    amplitude={appState.amplitude}
                    amplitudeLinkEvent="ClickLearnEVVAWallet"
                    imageShadow
                />
                {/*
                <Card
                    subtitle="EVVA Emprendedores"
                    description="<p>Comparte tu proyecto, promueve tus ideas y productos en nuestra comunidad</p>"
                    descriptionColor="#363636"
                    image="logo-evva-emprendedores.svg"
                    imageWidth="180px"
                    imageHeight="200px"
                    imageBgColor="_evva"
                    link="/en"
                    linkLabel="Conoce más"
                    amplitude={appState.amplitude}
                    amplitudeLinkEvent="ClickLearnEVVAEntrepreneurs"
                    imageShadow
                />
                */}
            </PageSection>
            <PageSection maxWidth="980px" bgColor="#F9F9F9">
                <Card
                    title="Únete a nuestra misión"
                    description="<p>¿Eres un banco, fintech o startup y compartes nuestra misión?</p>"
                    descriptionColor="#363636"
                    actionLabel="Contáctanos"
                    action={contactUsFormHandler}
                    image="legal-guide.png"
                    imageWidth="420px"
                    imageHeight="340px"
                    marginTopContent="60px"
                    horizontal
                    padding
                />
            </PageSection>
            <PageSection title="Preguntas frecuentes" >
                <FAQs items={dataFAQs} filters={dataFilters} categoryFAQsHandler={categoryFAQsHandler} categoryFAQs={appState.categoryFAQs}/>
            </PageSection>
            <SecondarySection
                title="Juntos crecemos"
                shortDescription="De emprendedores para emprendedores. Somos gente como tú que entiende tus necesidades. Nuestro objetivo final es que te enfoques en lo que realmente importa: tu empresa."
                position="center"
                image="evva-together.jpg"
                actionLabel="Solicita tu invitación"
                action={marketplaceFormHandler}
                amplitude={appState.amplitude}
                amplitudeClickEvent="ClickPreSignUpMKT"
            />
        </Layout>
    )
};

export default Marketplace
